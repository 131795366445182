import Vue from 'vue';
import store from './store';
import router from './router';
import Vue2TouchEvents from 'vue2-touch-events';
import BibleCloudApp from './components/BibleCloudApp';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

require('../bootstrap');
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

Vue.use(Vue2TouchEvents, {
    disableClick: true
});

Bugsnag.start({
    apiKey: process.env.BIBLE_CLOUD_BUGSNAG_API_KEY,
    enabledReleaseStages: ['production', 'staging'],
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
    appVersion: window.app_version,
    metadata: {
        name: window.Church.name,
        slug: window.Church.slug,

    }
});

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
    el: '#app',
    router: router,
    store: store,
    components: {
        'bible-cloud-app': BibleCloudApp
    }
});
