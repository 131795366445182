<template>
    <bible-cloud-dropdown
        :buttonText="currentTranslation.abbreviation"
        placeholderText="Translation"
    >
        <div class="bibleCloudTranslationSelector-menu">
            <div class="bibleCloudDropdown-section-title">
                selected translation
            </div>

            <div class="bibleCloudTranslationSelector-link bibleCloudTranslationSelector-link-current">
                {{currentTranslation.name}}
            </div>

            <div class="bibleCloudDropdown-section-title">
                other versions
            </div>
            <template v-for="bible in $store.state.bibles">
                <router-link
                    class="bibleCloudTranslationSelector-link"
                    :to="getLink(bible)"
                >
                    {{bible.name}}
                </router-link>
            </template>
        </div>
    </bible-cloud-dropdown>
</template>

<script>
    import BibleCloudDropdown from '@app/BibleCloudDropdown';

    export default {
        components: {
            BibleCloudDropdown,
        },
        props: {
            currentTranslation: Object
        },
        methods: {
            /**
             * Get a link that will change the translation
             *
             * @param {Object} bible - Information about a specific bible translation
             * @return {string}
             */
            getLink(bible) {
                // If the url has a bible abbreviation in it, just update the abbreviation
                if (this.$route.params.bibleAbbreviation) {
                    return this.$route.fullPath.replace(
                        this.$route.params.bibleAbbreviation,
                        bible.abbreviation.toLowerCase()
                    );
                } else {
                    // If the url doesn't have an abbreviation, it must be the home page ('/'), so navigate to a chapter
                    return this.$store.getters.getChapterUrl(
                        bible.abbreviation,
                        this.$store.getters.currentChapterId
                    );
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // Customize the horizontal position of the dropdown menu to keep it from falling off the page
    ::v-deep {
        .dropdown-menu {
            margin-left: 0;
        }
    }

    // Customize the spacing of the dropdown menu
    .bibleCloudTranslationSelector-menu {
        margin-top: 8px;
    }

    // Override the spacing of section titles
    .bibleCloudDropdown-section-title {
        margin: 0 9px;
    }

    // Each clickable translation in the list of translations
    .bibleCloudTranslationSelector-link {
        color: $textColor;
        display: block;
        text-decoration: none;

        margin: 4px 0;
        padding: 6px 19px;

        &.bibleCloudTranslationSelector-link-current,
        &:hover {
            background: $veryLightGray;
        }
    }
</style>
