<template>
    <bible-cloud-dropdown
        @dropdownShown="dropdownShown"
        @dropdownHidden="dropdownHidden"
        :buttonText="currentBook.name"
        :disabled="disabled"
        placeholderText="Book"
    >
        <div class="bibleCloudBookSelector-menu">
            <bible-cloud-search-input
                @inputUpdated="searchText = $event"
                ref="searchInput"
            ></bible-cloud-search-input>

            <div class="row pt-4">
                <div class="col-6 pr-0">
                    <div
                        class="bibleCloudDropdown-section-title"
                        :class="{'bibleCloudBookSelector-section-title-disabled': !oldTestamentBooks.length}"
                    >
                        old testament
                    </div>

                    <template  v-for="oldTestamentBook in oldTestamentBooks">
                        <router-link
                            class="bibleCloudBookSelector-book text-truncate"
                            :to="getBookUrl(oldTestamentBook)"
                        >
                            {{oldTestamentBook.name}}
                        </router-link>
                    </template>
                </div>

                <div class="col-6 pl-0">
                    <div
                        class="bibleCloudDropdown-section-title"
                        :class="{'bibleCloudBookSelector-section-title-disabled': !newTestamentBooks.length}"
                    >
                        new testament
                    </div>

                    <template v-for="newTestamentBook in newTestamentBooks">
                        <router-link
                            class="bibleCloudBookSelector-book text-truncate"
                            :to="getBookUrl(newTestamentBook)"
                        >
                            {{newTestamentBook.name}}
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </bible-cloud-dropdown>
</template>

<script>
    import BibleCloudDropdown from '@app/BibleCloudDropdown';
    import BibleCloudSearchInput from '@app/BibleCloudSearchInput';

    export default {
        components: {
            BibleCloudDropdown,
            BibleCloudSearchInput
        },
        props: {
            disabled: Boolean
        },
        data() {
            return {
                fullNewTestamentBooks: [],
                fullOldTestamentBooks: [],
                newTestamentBooks: [],
                oldTestamentBooks: [],
                searchText: ''
            };
        },
        created() {
            // Generate the dropdown content when the page 1st loads
            this.buildBookLists();
        },
        computed: {
            /**
             * Store the state's books array for easy referencing
             *
             * @return {Array}
             */
            books() {
                return this.$store.state.books;
            },

            /**
             * Store the state's currentBook object for easy referencing
             *
             * @return {Object}
             */
            currentBook() {
                return this.$store.getters.currentBook;
            }
        },
        watch: {
            /**
             * Rebuild the list of books whenever we get new books data (when translations change)
             */
            books() {
                this.buildBookLists();
            },

            /**
             * Reduce the list of books displayed to match the text that was searched
             */
            searchText() {
                // If there is an autocomplete value, only show books that match
                if (this.searchText.length) {
                    this.oldTestamentBooks = this.fullOldTestamentBooks.filter(book => {
                        return book.name.toLowerCase().includes(this.searchText.toLowerCase());
                    });

                    this.newTestamentBooks = this.fullNewTestamentBooks.filter(book => {
                        return book.name.toLowerCase().includes(this.searchText.toLowerCase());
                    });
                } else {
                    // Return old & new testaments back to the full list
                    this.oldTestamentBooks = this.fullOldTestamentBooks;
                    this.newTestamentBooks = this.fullNewTestamentBooks;
                }
            }
        },
        methods: {
            buildBookLists() {
                // Clear out any previously recorded books so we don't get duplicates when the translation changes
                this.fullOldTestamentBooks = [];
                this.fullNewTestamentBooks = [];

                // Save each book in it's appropriate testament so we can display them as separate lists
                this.books.forEach(book => {
                    // Once we hit matthew add it and all books after it to the new testament books
                    if (book.name.toLowerCase() === 'matthew' || this.fullNewTestamentBooks.length) {
                        this.fullNewTestamentBooks.push(book);
                    } else {
                        this.fullOldTestamentBooks.push(book);
                    }
                });

                // Update the lists that we're displaying with all the books
                this.oldTestamentBooks = this.fullOldTestamentBooks;
                this.newTestamentBooks = this.fullNewTestamentBooks;
            },

            /**
             * Update the url when a book has been selected from the list
             *
             * @param {Object} book
             */
            getBookUrl(book) {
                const firstBook = book.chapters[0];

                return this.$store.getters.getChapterUrl(
                    this.$store.state.currentBibleAbbreviation,
                    firstBook.id
                );
            },

            /**
             * The dropdown menu has finished opening
             */
            dropdownShown() {
                // Select the search input so users can start typing to search through books right away
                this.$refs.searchInput.select();
            },

            /**
             * The dropdown menu has finished closing
             */
            dropdownHidden() {
                // Clear out the search text input
                this.$refs.searchInput.clear();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // Customize the spacing of the dropdown menu
    .bibleCloudBookSelector-menu {
        margin: 25px 25px 0 25px;
    }

    // Gray out the title if the search results remove all books from the list under it
    .bibleCloudBookSelector-section-title-disabled {
        opacity: 0.4;
    }

    // Each book in the list of book names
    .bibleCloudBookSelector-book {
        color: $textColor;
        display: block;
        margin: 8px 0;
        padding: 0 11px;
        text-decoration: none;

        &:hover {
            background: $veryLightGray;
        }
    }
</style>
