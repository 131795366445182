<template>
    <div class="bibleCloudApp">
        <bible-cloud-layout
            @navToggled="handleNavToggled"
            @overlayClicked="hideMobileSearch"
            @sidebarToggled="isSidebarExpanded = $event"
            :church="church"
            :createPrayerUrl="createPrayerUrl"
            :overlayClass="overlayClass"
            :settings="settings"
            :showOverlay="showOverlay"
            :site="site"
        >
            <template v-slot:navbar-extras>
                <!-- Toggle button to show / hide the mobile search bar -->
                <a
                    @click.prevent="toggleMobileSearch"
                    class="bibleCloudApp-search-toggle d-flex d-md-none btn"
                    :class="{'disabled': navShown}"
                    href="#"
                >
                    <simple-svg
                        class="bibleCloudApp-search-icon d-flex"
                        :filepath="getSvgFilePath('search')"
                        height="16px"
                    ></simple-svg>
                </a>

                <template v-if="showMobileSearch">
                    <form
                        @submit.prevent="submitSearch"
                        class="bibleCloudApp-search d-flex d-md-none"
                    >
                        <bible-cloud-search-input
                            @inputUpdated="searchText = $event"
                            :value="searchText"
                        ></bible-cloud-search-input>
                    </form>
                </template>
            </template>

            <!-- Add stream specific information to the details tab -->
            <template
                v-slot:details
                v-if="settings.details"
            >
                <span v-html="settings.details"></span>
            </template>

            <template v-slot:content>
                <template v-if="loading">
                    <div class="bibleCloudApp-loading-overlay col-12 col-lg-9">
                        <b-spinner class="bibleCloudApp-loading-spinner"></b-spinner>
                    </div>
                </template>

                <!-- All components require books, so prevent them from being rendered until the ajax returns -->
                <div
                    v-if="$store.state.books.length"
                    v-touch:swipe.left="swipeLeftHandler"
                    v-touch:swipe.right="swipeRightHandler"
                    class="bibleCloudApp-content"
                >
                    <div class="row pt-4">
                        <div class="col d-none d-md-block">
                            <template v-if="!showSearch">
                                <!-- Toggle button to show the desktop search bar -->
                                <a
                                    @click.prevent="showSearch = !showSearch"
                                    class="bibleCloudApp-search-toggle"
                                    href="#"
                                >
                                    <simple-svg
                                        class="bibleCloudApp-search-icon d-flex"
                                        :filepath="getSvgFilePath('search')"
                                        height="16px"
                                    ></simple-svg>
                                </a>
                            </template>
                            <template v-else>
                                <form
                                    @submit.prevent="submitSearch"
                                     class="bibleCloudApp-search"
                                >
                                    <bible-cloud-search-input
                                        @inputUpdated="searchText = $event"
                                        class="bibleCloudApp-search-input"
                                        :value="searchText"
                                    ></bible-cloud-search-input>
                                </form>
                            </template>
                        </div>
                        <div class="col bibleCloudApp-dropdown-container">
                            <bible-cloud-book-selector
                                class="bibleCloudApp-dropdown"
                                :disabled="isSearchPage"
                            ></bible-cloud-book-selector>

                            <bible-cloud-chapter-selector
                                class="bibleCloudApp-dropdown"
                                :disabled="isSearchPage"
                            ></bible-cloud-chapter-selector>

                            <bible-cloud-translation-selector
                                class="bibleCloudApp-dropdown"
                                :currentTranslation="currentTranslation"
                            ></bible-cloud-translation-selector>
                        </div>
                    </div>

                    <router-view
                        @clearSearch="clearSearch"
                        @loading="loading = $event"
                        @showSearch="showSearch = true"
                        :base_domain="base_domain"
                        :currentTranslation="currentTranslation"
                        :eventBus="eventBus"
                        :isSidebarExpanded="isSidebarExpanded"
                    ></router-view>
                </div>
            </template>
        </bible-cloud-layout>
    </div>
</template>

<script>
    import axios from "axios";
    import BibleCloudBookSelector from '@app/BibleCloudBookSelector';
    import BibleCloudChapterSelector from '@app/BibleCloudChapterSelector';
    import BibleCloudLayout from '@sermon-cloud/shared/components/LiveStreamAndBibleCloudLayout';
    import BibleCloudSearchInput from '@app/BibleCloudSearchInput';
    import BibleCloudTranslationSelector from '@app/BibleCloudTranslationSelector';
    import BSpinner from '@bootstrap-vue/spinner/spinner'
    import {getSvgFilePathMixin} from '@sermon-cloud/mixins/getSvgFilePathMixin';
    import {SimpleSVG} from 'vue-simple-svg';
    import Vue from 'vue';

    export default {
        components: {
            BibleCloudBookSelector,
            BibleCloudChapterSelector,
            BibleCloudLayout,
            BibleCloudSearchInput,
            BibleCloudTranslationSelector,
            BSpinner,
            'simple-svg': SimpleSVG
       },
        mixins: [getSvgFilePathMixin],
        data() {
            return {
                base_domain: window.base_domain,
                church: window.Church,
                createPrayerUrl: window.createPrayerUrl,
                eventBus: new Vue(),
                loading: true,
                isSidebarExpanded: false,
                navHidden: true,
                navShown: false,
                searchText: this.$route.query.q,
                showMobileSearch: false,
                showOverlay: false,
                showSearch: false,
                settings: window.settings,
                site: window.site
            };
        },
        created() {
            this.$store.dispatch('updateCurrentBibleAbbreviation', this.$route.params.bibleAbbreviation);
            this.$store.dispatch('updateCurrentBookName', this.$route.params.bookName);
            this.$store.dispatch('updateCurrentChapterNumber', this.$route.params.chapterNumber);
        },
        computed: {
            /**
             * The bible translation to be used for all api requests
             *
             * @return {Object}
             */
            currentTranslation() {
                return this.$store.state.bibles.filter((bible) => {
                    return bible.abbreviation.toLowerCase() === this.$store.state.currentBibleAbbreviation.toLowerCase();
                })[0];
            },

            /**
             * Ensure the mobile search overlay is hidden on medium & larger devices
             */
            overlayClass() {
                if (this.showMobileSearch) {
                    return 'd-md-none';
                }
            },

            /**
             * Keep track of the search page, as we will need to disable the book & chapter dropdowns
             */
            isSearchPage() {
                return this.$route.path.includes('search');
            }
        },
        watch: {
            /**
             * Watch for changes to the url route and update our saved values
             * Doing this instead of computing directly on the $route ensures our computed methods are only triggered
             * whenever these specific values change, because the entire $route object updates with every url change
             *
             * @param {Object} to - the value the url route has changed to
             */
            $route(to) {
                // Update the information about the display every time the url changes
                this.$store.dispatch('updateCurrentBibleAbbreviation', to.params.bibleAbbreviation);
                this.$store.dispatch('updateCurrentBookName', this.$route.params.bookName);
                this.$store.dispatch('updateCurrentChapterNumber', this.$route.params.chapterNumber);

                // Fill the search input with the search query when present
                if (to.query.q) {
                    this.searchText = to.query.q;
                }
            },

            /**
             * Download the books and chapters whenever the current translation is set or changed
             */
            currentTranslation() {
                this.getBooksAndChapters();
            }
        },
        methods: {
            /**
             * Send a left swipe event down to the router-view
             */
            swipeLeftHandler() {
                this.eventBus.$emit('swipeLeftHandler');
            },

            /**
             * Send a right swipe event down to the router-view
             */
            swipeRightHandler() {
                this.eventBus.$emit('swipeRightHandler');
            },

            /**
             * Download the books and chapters for the current bible translation
             */
            getBooksAndChapters() {
                this.loading = true;

                // Load data
                axios({
                    url: `//api.${this.base_domain}/${this.currentTranslation.id}/books`,
                    method: 'get'
                })
                // Ajax success
                .then(response => {
                    this.loading = false;
                    this.$store.dispatch('updateBooks', response.data.data);
                });
            },

            /**
             * Hide or show the mobile search and overlay
             */
            toggleMobileSearch() {
                this.showMobileSearch = !this.showMobileSearch;
                this.showOverlay = this.showMobileSearch;
            },

            /**
             * Hide the mobile search and overlay
             */
            hideMobileSearch() {
                this.showMobileSearch = false;
                this.showOverlay = false;
            },

            /**
             * Hide the mobile search bar and disable the toggle when the navigation is expanded
             */
            handleNavToggled(isNavShown) {
                this.hideMobileSearch();
                this.navShown = isNavShown;
            },

            /**
             * Navigation users to the search page
             */
            submitSearch() {
                this.hideMobileSearch();
                this.$router.push('/' + this.$store.state.currentBibleAbbreviation + '/search?q=' + this.searchText);
            },

            /**
             * Remove all text from the search input and hide it
             */
            clearSearch() {
                this.searchText = '';
                this.showSearch = false;
            }
        }
    }
</script>
<style lang="scss">
    // Load the api.bible styles
    @import "~scripture-styles/scss/scripture-styles";
</style>

<style lang="scss" scoped>
    @import '~sass/variables';

    .bibleCloudApp-loading-spinner {
        z-index: $navigationArrowZindex + 3;
    }

    .bibleCloudApp-loading-overlay {
        align-items: center;
        background: $white-70;
        display: flex;
        height: 100%;
        left: 0;
        justify-content: center;
        position: fixed;
        top: 0;
        z-index: $navigationArrowZindex + 2;

        // Make the loading overlay full width when there is no sidebar
        .liveStreamAndBibleCloudLayout-no-sidebar & {
            @include make-col(12);
        }
    }

    // Create a "card" that all page content will display inside
    .bibleCloudApp-content {
        border-radius: $cardBorderRadius;
        box-shadow: 0 0 41px $mediumGray;
        margin: 10px 40px;
        min-height: calc(100vh - 181px);
        padding: 0 $cardHorizontalPadding 25px $cardHorizontalPadding;

        * {
            font-family: $font-family-openSans;
        }
    }

    // Move dropdowns to the right of the column
    .bibleCloudApp-dropdown-container {
        display: flex;
        justify-content: flex-end;
        padding: 0;
    }

    // Button to open the search box, displayed next to the book dropdowns on larger browsers
    .bibleCloudApp-search-toggle {
        align-items: center;
        border: 1px solid $mediumGray;
        border-radius: 40px;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        width: 40px;

        &:hover {
            box-shadow: 0px 0px 14px $mediumGray;
        }
    }

    .bibleCloudApp-search-icon {
        fill: $textColor;
    }

    // Form containing the search field
    .bibleCloudApp-search {
        margin-right: 14px;

        ::v-deep .input-group {
            max-width: 330px;
        }
    }

    .bibleCloudApp-search-input {
        z-index: $zindex-dropdown;
    }

    // For screens sized "sm" and smaller
    @include media-breakpoint-down(sm) {
        // Move dropdowns back to the left of the column when the search input goes away os smaller screens
        .bibleCloudApp-dropdown-container {
            justify-content: normal;
        }

        // Make the dropdowns fill 100% of the column width while maintaining their relative width ratios
        .bibleCloudApp-dropdown {
            flex-grow: 1;
        }

        // When the search is in the header it should use the user defined colors to match the hamburger menu
        .bibleCloudApp-search-toggle {
            border: 1px solid var(--user-link-color);
            margin-right: 16px;
        }

        .bibleCloudApp-search-icon {
            fill: var(--user-link-color);
        }

        // Make the search bar cover the book dropdowns and appear inside the "card"
        .bibleCloudApp-search {
            justify-content: center;
            left: 0;
            position: absolute;
            top: $navbar-height + 31px;
            width: 100%;

            // Make the search bar wide enough to cover the book dropdowns while still remaining in the "card"
            ::v-deep .input-group {
                max-width: 77%;
            }
        }
    }

    // For screens sized "xs"
    @include media-breakpoint-down(xs) {
        // Remove the "card" look from the reader
        .bibleCloudApp-content {
            border-radius: $xsCardBorderRadius;
            box-shadow: none;
            margin: 0;
            padding: 0 $xsCardHorizontalPadding 25px $xsCardHorizontalPadding;
        }

        // Now that the "card" look is gone
        // - move the search bar so it still covers the book dropdowns
        // - make the search bar stretch almost the whole page
        .bibleCloudApp-search {
            padding: 0 14px;
            top: $navbar-height + 19px;

            ::v-deep .input-group {
                max-width: 100%;
            }
        }
    }
</style>
