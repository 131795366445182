<template>
    <div
        class="bibleCloudDropdown"
        ref="bibleCloudDropdown"
    >
        <!-- A full page overlay that grays out the content of the page on mobile devices
             and keeps all other dropdowns visible -->
        <div
            v-if="dropdownOpen"
            class="bibleCloudDropdown-overlay"
        ></div>

        <!-- Button that opens / closes the dropdown menu -->
        <button
            href="#"
            class="dropdown-toggle"
            :class="{'disabled': disabled}"
            data-toggle="dropdown"
            data-flip="false"
            ref="dropdownToggle"
        >
            <span class="text-truncate">
                <template v-if="buttonText && !disabled">
                    {{buttonText}}
                </template>
                <template v-else>
                    <span class="bibleCloudDropdown-placeholder-text">
                        {{placeholderText}}
                    </span>
                </template>
            </span>
            <span class="caret"></span>
        </button>

        <!-- Dropdown menu that gets opened / closed by the toggle button -->
        <div
            class="dropdown-menu"
            data-boundary="window"
            role="menu"
        >
            <div class="form-group">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            buttonText: String,
            disabled: Boolean,
            placeholderText: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                dropdownOpen: false
            };
        },
        mounted() {
            // Listen for the dropdown to open / close
            const $bibleCloudDropdown = $(this.$refs.bibleCloudDropdown);
            $bibleCloudDropdown.on('shown.bs.dropdown', this.dropdownShown);
            $bibleCloudDropdown.on('hidden.bs.dropdown', this.dropdownHidden);
        },
        destroyed() {
            // Stop listening for the dropdown to open / close
            const $bibleCloudDropdown = $(this.$refs.bibleCloudDropdown);
            $bibleCloudDropdown.off('shown.bs.dropdown');
            $bibleCloudDropdown.on('hidden.bs.dropdown');
        },
        methods: {
            /**
             * The dropdown menu has finished opening
             */
            dropdownShown() {
                this.dropdownOpen = true;

                // Let the parent component know the dropdown has been opened
                this.$emit('dropdownShown');
            },

            /**
             * The dropdown menu has finished closing
             */
            dropdownHidden() {
                this.dropdownOpen = false;

                // Remove focus when closing so the toggle doesn't stay highlighted when closed
                this.$refs.dropdownToggle.blur();

                // Let the parent component know the dropdown has been hidden
                this.$emit('dropdownHidden');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .bibleCloudDropdown {
        display: inline-block;
        margin: 0 6px;
        width: 116px;
    }

    // Button that opens / closes the dropdown
    .dropdown-toggle {
        align-items: center;
        background: $white;
        border: 1px solid $mediumGray;
        border-radius: 4px;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        line-height: 26px;
        padding: 6px 12px;
        position: relative;
        width: 100%;
        z-index: $navigationArrowZindex + 2;

        // Make the button look selected
        &:hover,
        &:focus,
        .show & {
            cursor: default;
            outline: none;

            &:not(.disabled) {
                border: 2px solid $darkBlue;
                cursor: pointer;
                padding: 5px 11px;

                &::after {
                    color: $darkBlue;
                }
            }
        }

        // Flip the dropdown caret when opened
        .show &::after {
            transform: rotate(180deg);
        }
    }

    // Text shown in the dropdown toggle while waiting for the selected content to load
    .bibleCloudDropdown-placeholder-text {
        color: $mediumGray;
    }

    // Menu that is opened / closed by the toggle button
    .dropdown-menu {
        border: none;
        border-radius: 4px;
        box-shadow: 2px 4px 8px $darkGray-20;
        padding: 0;
        width: 350px;
    }

    // Titles separating sections in the dropown menu
    .bibleCloudDropdown-section-title {
        color: $darkGray;
        font-size: 12px;
    }

    // For screens sized "sm" and larger
    @include media-breakpoint-up(sm) {
        .bibleCloudDropdown {
            position: relative;
        }

        // Center the dropdown under the toggle button
        .dropdown-menu {
            margin-left: 50%;
            transform: translate3d(-50%, 40px, 0px) !important;
        }
    }

    // For screens sized "sm" and smaller
    @include media-breakpoint-down(sm) {
        .dropdown-toggle {
            line-height: 18px;
        }
    }

    // For screens sized "xs"
    @include media-breakpoint-down(xs) {
        .bibleCloudDropdown-overlay {
            background: $darkGray-80;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $navigationArrowZindex + 1;
        }

        // Ensure the dropdown menu is centered on the screen
        .dropdown-menu {
            margin: 0 5px;
            width: 100% !important;
        }
    }
</style>
