import Vue from 'vue';
import VueRouter from 'vue-router';
import BibleCloudChapter from './components/BibleCloudChapter';
import BibleCloudError from './components/BibleCloudError';
import BibleCloudSearch from './components/BibleCloudSearch';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: BibleCloudChapter
        },
        {
            path: '/:bibleAbbreviation/search',
            component: BibleCloudSearch
        },
        // Handle urls with or without a verse number and with or without a verse range
        // Supports:
        // - /kjv/genesis
        // - /kjv/genesis/1
        // - /kjv/genesis/1/1
        // - /kjv/genesis/1/1-2
        // - /kjv/genesis/1/1-2/1
        // - /kjv/genesis/-1/2
        {
            path: '/:bibleAbbreviation/:bookName/:chapterNumber?/:verseNumber(.*)?',
            component: BibleCloudChapter
        },
        {
            path: '/error',
            name: 'error',
            component: BibleCloudError,
            props: (route) => ({
                status: route.params.status || '404',
                statusText: route.params.statusText || 'Not Found',
            })
        },
        {
            path: '*',
            component: BibleCloudError,
            props: {
                status:'404',
                statusText: 'Not Found',
            }
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {
                x: 0,
                y: 0
            };
        }
    },
});
