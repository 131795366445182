<template>
    <b-input-group class="bibleCloudSearchInput">
        <b-input-group-prepend is-text>
            <simple-svg
                class="bibleCloudSearchInput-icon d-flex"
                :filepath="getSvgFilePath('search')"
                height="16px"
            ></simple-svg>
        </b-input-group-prepend>

        <b-form-input
            v-model="inputValue"
            autocomplete="off"
            class="bibleCloudSearchInput-input"
            placeholder="Search"
            ref="input"
            type="search"
        ></b-form-input>
    </b-input-group>
</template>

<script>
    import BFormInput from '@bootstrap-vue/form-input/form-input';
    import BInputGroup from '@bootstrap-vue/input-group/input-group';
    import BInputGroupPrepend from '@bootstrap-vue/input-group/input-group-prepend';
    import {getSvgFilePathMixin} from '@sermon-cloud/mixins/getSvgFilePathMixin';
    import {SimpleSVG} from 'vue-simple-svg';

    export default {
        components: {
            BFormInput,
            BInputGroup,
            BInputGroupPrepend,
            'simple-svg': SimpleSVG
       },
        mixins: [getSvgFilePathMixin],
        props: {
            value: String
        },
        data() {
            return {
                inputValue: this.value
            };
        },
        watch: {
            /**
             * Whenever the parent element changes value update what we display
             */
            value() {
                this.inputValue = this.value;
            },

            /**
             * Let the parent component know when the text in the input has changed
             */
            inputValue() {
                this.$emit('inputUpdated', this.inputValue);
            }
        },

        methods: {
            /**
             * Select the text input so a user can start typing
             */
            select() {
                this.$refs.input.select();
            },

            /**
             * Clear out the text input
             */
            clear() {
                this.inputValue = '';
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~sass/variables';

    // Input group containing the search field
    .bibleCloudSearchInput {
        ::v-deep .input-group-text {
            background: $white;
            border: 1px solid $mediumGray;
            border-right: none;
        }
    }

    // Text field for searching
    .bibleCloudSearchInput-input {
        border-left: none;
        line-height: 26px;
        padding-left: 0;

        &:focus {
            border-color: $mediumGray;
            box-shadow: none;
        }
    }

    // Color for the search svg magnifying glass
    .bibleCloudSearchInput-icon {
        fill: $textColor;
    }
</style>
