<template>
    <div class="bibleCloudChapter">
        <template v-if="chapter">
            <router-link
                class="bibleCloudChapter-previous"
                :class="{
                    'bibleCloudChapter-sidebar-expanded': isSidebarExpanded,
                    'bibleCloudChapter-arrow-disabled': !hasPreviousChapter
                }"
                :to="previousChapterUrl"
            >
                <simple-svg
                    class="bibleCloudChapter-arrow-icon"
                    :filepath="getSvgFilePath('chevron-left')"
                    height="24px"
                ></simple-svg>
            </router-link>

            <router-link
                class="bibleCloudChapter-next"
                :class="{
                    'bibleCloudChapter-sidebar-expanded': isSidebarExpanded,
                    'bibleCloudChapter-arrow-disabled': !hasNextChapter
                }"
                :to="nextChapterUrl"
            >
                <simple-svg
                    class="bibleCloudChapter-arrow-icon"
                    :filepath="getSvgFilePath('chevron-right')"
                    height="24px"
                ></simple-svg>
            </router-link>

            <!-- Sticky title container scrolls with the page a bit, then "sticks" and gets a  drop shadow -->
            <div
                class="bibleCloudChapter-sticky-title-container"
                :class="stickBookClass"
                ref="bibleCloudChapter-sticky-title-container"
            >
                <!-- Hide the chapter title on "intro" pages (which have no chapter number)
                  -- as they will display the full book name, duplicating the title
                  -->
                <h1
                    v-if="!isNaN(chapter.number)"
                    v-html="chapterTitle"
                    class="bibleCloudChapter-chapter-title"
                ></h1>
            </div>

            <!-- The actual scripture text, needs "scripture-styles" class to apply api.bible's css -->
            <div
                class="scripture-styles"
                ref="scripture-styles"
            >
                <div
                    v-if="!isNaN(chapter.number)"
                    v-html="chapter.number"
                    class="bibleCloudChapter-chapter-number"
                ></div>

                <div v-html="chapter.content"></div>
            </div>

            <div
                v-html="chapter.copyright"
                class="bibleCloudChapter-copyright"
            ></div>
        </template>
    </div>
</template>

<script>
    import axios from 'axios';
    import {getSvgFilePathMixin} from '@sermon-cloud/mixins/getSvgFilePathMixin';
    import {SimpleSVG} from 'vue-simple-svg';

    export default {
        components: {
            'simple-svg': SimpleSVG
        },
        mixins: [getSvgFilePathMixin],
        props: {
            base_domain: String,
            currentTranslation: Object,
            eventBus: Object,
            isSidebarExpanded: Boolean
        },
        data() {
            return {
                stickBookClass: '',
                storedChapters: {},
                throttleTimeout: null
            };
        },
        created() {
            // Listen for the scroll event so we can add / remove a drop shadow from the sticky book div
            window.addEventListener('scroll', this.handleScroll);

            // Get api.bible data when the page 1st loads
            this.updateChapter();

            // Listen for a keyboard interaction (when outside of all form inputs) so we can navigate between chapters
            window.addEventListener('keyup', this.handleKeyUp);

            // Listen for swipe events on the parent content so we can navigate between chapters on mobile
            this.eventBus.$on('swipeLeftHandler', this.swipeLeftHandler);
            this.eventBus.$on('swipeRightHandler', this.swipeRightHandler);
        },
        destroyed () {
            // Remove the scroll event listener if we stop using this component
            window.removeEventListener('scroll', this.handleScroll);

            // Remove the keyup event listener if we stop using this component
            window.removeEventListener('keyup', this.handleKeyUp);

            // Remove swipe event listeners if we stop using this component
            this.eventBus.$off('swipeLeftHandler', this.swipeLeftHandler);
            this.eventBus.$off('swipeRightHandler', this.swipeRightHandler);
        },
        computed: {
            /**
             * Build the chapter title
             *
             * @return {String}
             */
            chapterTitle() {
                // api.bible's title contains the chapter number
                // but we're displaying the chapter number inline with the scripture
                // so here we remove the chapter number from the chapter title so it isn't duplicated
                const regex = new RegExp(this.chapter.number + '$', 'g');
                return this.chapter.reference.replace(regex, '');
            },

            /**
             * Determine if there is a chapter before this
             *
             * @return {Boolean}
             */
            hasPreviousChapter() {
                return this.chapter && this.chapter.previous;
            },

            /**
             * Determine if there is a chapter after this
             *
             * @return {Boolean}
             */
            hasNextChapter() {
                return this.chapter && this.chapter.next;
            },

            /**
             * Create a url that will navigate to the previous chapter
             */
            previousChapterUrl() {
                if (this.hasPreviousChapter) {
                    return this.$store.getters.getChapterUrl(
                        this.$store.state.currentBibleAbbreviation,
                        this.chapter.previous.id
                    );
                } else {
                    return '';
                }
            },

            /**
             * Create a url that will navigate to the next chapter
             */
            nextChapterUrl() {
                if (this.hasNextChapter) {
                    return this.$store.getters.getChapterUrl(
                        this.$store.state.currentBibleAbbreviation,
                        this.chapter.next.id
                    );
                } else {
                    return '';
                }
            },

            /**
             * Update the chapter we display whenever there's a change to the storedChapters
             *
             * @return {Object|String}
             */
            chapter() {
                // Whenever the currentChapterId updates change the chapter being displayed
                const url = this.getAjaxUrl(this.$store.getters.currentChapterId);
                const chapter = this.getStoredChapter(url);

                // The chapter exists and contains information
                if (typeof chapter === 'object') {
                    this.$emit('loading', false);

                    if (this.$route.params.verseNumber) {
                        this.$nextTick(() => {
                            this.scrollToVerse();
                        });
                    }

                    // If there is a previous chapter that is not stored / loading, load it up
                    if (chapter.previous && !this.getStoredChapter(this.getAjaxUrl(chapter.previous.id))) {
                        this.updateChapter(chapter.previous.id);
                    }

                    // If there is a next chapter that is not stored / loading, load it up
                    if (chapter.next && !this.getStoredChapter(this.getAjaxUrl(chapter.next.id))) {
                        this.updateChapter(chapter.next.id);
                    }
                } else {
                    this.$emit('loading', true);
                }

                return chapter;
            }
        },
        watch: {
            /**
             * When the route changes load the appropriate chapter
             */
            $route() {
                this.updateChapter();
            }
        },
        methods: {
            /**
             * Add / remove a drop shadow from the sticky title container based on scroll position
             */
            handleScroll() {
                if (this.throttleTimeout) {
                    return;
                }

                this.throttleTimeout = setTimeout(() => {
                    const topOfScripture = this.$refs["scripture-styles"].getBoundingClientRect().top;
                    const bottomOfSticky = this.$refs["bibleCloudChapter-sticky-title-container"].getBoundingClientRect().bottom;

                    // Get the top of the scripture
                    // determine if it is above the bottom of the sticky header
                    if (topOfScripture < bottomOfSticky) {
                        this.stickBookClass = 'bibleCloudChapter-sticky-title-container-shadow';
                    } else {
                        this.stickBookClass = '';
                    }
                    this.throttleTimeout = null;
                }, 20);
            },

            /**
             * Change chapters when the user hits the left / right keyboard arrow
             *
             * @param {KeyboardEvent} e
             */
            handleKeyUp(e) {
                // Left key goes to the previous chapter
                if (e.keyCode === 37) {
                    this.$router.push(this.previousChapterUrl);
                } else if (e.keyCode == 39) {
                    // Right key goes to the next chapter
                    this.$router.push(this.nextChapterUrl);
                }
            },

            /**
             * Navigate to the previous chapter on right swipe
             */
            swipeRightHandler() {
                this.$router.push(this.previousChapterUrl);
            },

            /**
             * Navigate to the next chapter on left swipe
             */
            swipeLeftHandler() {
                this.$router.push(this.nextChapterUrl);
            },

            /**
             * Build the url for requesting chapters
             *
             * @param {String} chapterId
             */
            getAjaxUrl(chapterId) {
                return `//api.${this.base_domain}/${this.currentTranslation.id}/chapters/${chapterId}`;
            },

            /**
             * Download data for a specific chapter and store it
             *
             * @param {String} chapterId
             */
            updateChapter(chapterId) {
                chapterId = chapterId || this.$store.getters.currentChapterId;
                const url = this.getAjaxUrl(chapterId);
                const storedChapter = this.getStoredChapter(url);

                // We have not stored anything for the chapter yet so we need to kick off the loading process
                if (!storedChapter) {
                    // Add some data to the stored chapter to prevent ajax loading more than 1 time
                    this.storeChapter(url, 'loading');

                    // Load data
                    axios({
                        url: url,
                        method: 'get'
                    })
                    // Ajax success
                    .then(response => {
                        // Add the ajax response to the stored chapters so it can get displayed when needed
                        this.storeChapter(response.config.url, response.data.data);
                    })
                    // Ajax error
                    .catch((error) => {
                        // Display the error page without updating html history
                        this.$router.replace({
                            name: 'error',
                            params: {
                                status: error.request.status.toString(),
                                statusText: error.request.statusText
                            }
                        });
                    });
                }
            },

            /**
             * Get chapter data that we have already fetched from api.bible
             *
             * @return {Object}
             */
            getStoredChapter(url) {
                return this.storedChapters[url];
            },

            /**
             * Store the chapter data returned by api.bible
             *
             * @param {String} url
             * @param {String} data
             */
            storeChapter(url, data) {
                this.$set(this.storedChapters, url, data);
            },

            /**
             * Scroll directly to a verse defined in the url
             *
             * @param {Boolean} noFontFaceSupport - if the browser can't detect web fonts, only delay the scrolling 1 time
             */
            scrollToVerse(noFontFaceSupport) {
                // Wait until the web font for scripture styles has loaded, as it will change the location of the verse
                // Check to see if the browser has the ability to detect web fonts being loaded
                if (document.fonts) {
                    // If the font used in the scripture styles is not yet loaded, wait a bit and try again
                    if (!document.fonts.check('18px "Noto Serif"')) {
                        setTimeout(() => {
                            this.scrollToVerse();
                        }, 100);

                        return;
                    }
                } else if (!noFontFaceSupport) {
                    // If the browser can't check for the web font, wait a bit and hopefully it will be downloaded
                    setTimeout(() => {
                        this.scrollToVerse(true);
                    }, 200);

                    return;
                }

                const verseNumber = this.$route.params.verseNumber.split('-')[0];
                const el = document.querySelectorAll('.v[data-number="' + verseNumber + '"]')[0];
                const elTop = el.offsetTop;
                const navbarHeight = document.querySelector('.navbar').offsetHeight;
                const chapterTitleHeight = document.querySelector('.bibleCloudChapter-sticky-title-container').offsetHeight;

                window.scrollTo({
                    top: elTop - navbarHeight - chapterTitleHeight,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // The header with the book / chapter / translation dropdowns and chapter title
    // will scroll with the page a little and then stick to the top
    .bibleCloudChapter-sticky-title-container {
        background: $white;
        border-top-left-radius: $cardBorderRadius;
        border-top-right-radius: $cardBorderRadius;
        margin: 0 $cardHorizontalPadding * -1; // make the sticky-title-container hit the edges of the card
        position: sticky;
        padding: 24px 24px 10px 24px;
        top: 65px;
    }

    // A drop shadow gets dynamically added to the sticky title container when we've scrolled far enough down the page
    .bibleCloudChapter-sticky-title-container-shadow {
        box-shadow: 0 8px 24px $mediumGray;
    }

    .bibleCloudChapter-chapter-title {
        font-family: $font-family-baskervville;
        margin-bottom: 0;
        text-align: center;
    }

    // Chapter number, displayed large next to the scripture
    .bibleCloudChapter-chapter-number {
        float: left;
        font-family: $font-family-notoSerif;
        font-size: 137px;
        line-height: 100px;
        margin-right: 16px;
    }

    // Navigation arrows
    .bibleCloudChapter-previous,
    .bibleCloudChapter-next {
        align-items: center;
        background: $white;
        border: 1px solid $mediumGray;
        border-radius: 100px;
        box-shadow: 0 0 4px $mediumGray;
        display: flex;
        height: 42px;
        justify-content: center;
        position: fixed;
        top: 50%;
        width: 42px;
        z-index: $navigationArrowZindex;

        &:hover {
            background: $mediumGray;
        }

        // Make the arrow look like it's being pushed down when clicked
        &:active {
            box-shadow: none;
        }
    }

    .bibleCloudChapter-previous {
        left: $navigationArrowHorizontalPosition;
    }

    // On large browsers, when the sidebar is displayed on the right
    // Make the next arrow display over the card by accounting for the 25% width of the sidebar
    .bibleCloudChapter-next {
        right: calc(25% + #{$navigationArrowHorizontalPosition});

        // Move the "next" navigation arrow further right when there is no sidebar
        .liveStreamAndBibleCloudLayout-no-sidebar & {
            right: $navigationArrowHorizontalPosition;
        }
    }

    .bibleCloudChapter-arrow-icon {
        fill: $darkGray;
    }

    .bibleCloudChapter-arrow-disabled {
        border: none;
        box-shadow: none;
        cursor: default;
        opacity: 0.5;

        &:hover {
            background: $white;
        }
    }

    .bibleCloudChapter-copyright {
        color: $darkGray;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
    }

    // Add space between the scripture and the sticky title container to give a good scroll / shadow effect
    .scripture-styles {
        padding-top: 15px;

        // Bible cloud style overrides
        ::v-deep {
            line-height: 38px;

            // "Standout text"
            .s1,
            .s2,
            .s3,
            .s4 {
                font-family: $font-family-sansita;
                font-size: 12px;
                font-style: italic;
                font-weight: normal;
                line-height: normal;
                margin-bottom: 1rem;
                text-align: left;
            }

            // "Verse number"
            // Stand out too much when bold
            .v,
            .vp,
            sup[class^=v] {
                font-weight: normal;
            }

            // "Paragraphs"
            // Did not have any space between them
            p,
            .p {
                margin-bottom: 1rem;
            }

            // "Major Titles", found on .intro pages
            // Remove additional space to make these fit in the card appropriately
            .mt,
            .mt1,
            .mt2 {
                margin-top: 0;
            }

            // "Words of Jesus"
            // Remove the red coloring and make it the same color as the rest of our scripture
            .wj {
                color: $textColor;
            }

            // "Blank link"
            // Were taking up too much space
            .b {
                height: auto;
            }

            // "Right aligned poetic line"
            // Right aligning text with our responsive design puts some of these too far away from the scripture
            .qr {
                text-align: left;
            }

            // Descriptive title (or "Hebrew subtitle")
            // Make bold to stand out and remove the center align so they look correct with our responsive page
            .d {
                font-weight: bold;
                text-align: left;
            }
        }
        // End bible cloud style overrides
    }

    // For screens sized "md" and narrower, when the sidebar becomes a footer
    @include media-breakpoint-down(md) {
        // The next arrow no longer needs to account for the sidebar
        .bibleCloudChapter-next {
            right: $navigationArrowHorizontalPosition;
        }

        // Navigation arrows move up when the footer (sidebar) is opened
        .bibleCloudChapter-sidebar-expanded {
            top: calc(45% - 17vh);
        }
    }

    // For screens sized "xs"
    @include media-breakpoint-down(xs) {
        // The sticky header moves a bit closer to the edge of the page
        // and will now scroll to hide the book / chapter / translation dropdowns
        .bibleCloudChapter-sticky-title-container {
            border-radius: $xsCardBorderRadius;
            margin: 0 $xsCardHorizontalPadding * -1; // make the sticky-title-container hit the edges of the page
            padding: 13px 10px 10px 10px;
            top: 76px;
        }

        // Navigation arrows move to the bottom of the page
        .bibleCloudChapter-previous,
        .bibleCloudChapter-next {
            bottom: 97px;
            top: auto;

            // Navigation arrows move up when the footer (sidebar) is opened
            &.bibleCloudChapter-sidebar-expanded {
                bottom: calc(97px + 35vh);
            }

            // Move the navigation arrows further down the page when there is no sidebar on the bottom
            .liveStreamAndBibleCloudLayout-no-sidebar & {
                bottom: 70px;
            }
        }

        // The previous arrow moves closer to the edge of the page
        .bibleCloudChapter-previous {
            left: $xsNavigationArrowHorizontalPosition;
        }

        // The next arrow moves closer to the edge of the page
        .bibleCloudChapter-next {
            right: $xsNavigationArrowHorizontalPosition !important;
        }
    }
</style>
