<template>
    <div id="prayers">
        <span v-html="prayer.introduction_text"></span>

        <p>
            <a
                @click.prevent="createNewPrayer"
                href="#"
                id="p1-share"
                class="btn btn-outline-secondary"
            >
                Share Your Prayer
            </a>
        </p>

        <template v-if="prayers">
            <template v-if="!prayers.length">
                <p>There are currently no prayer requests available.</p>
            </template>
            <template v-else>
                <div class="p1-layout-list">
                    <article v-for="singlePrayer in prayers">
                        <div class="info">
                            <h3>
                                {{singlePrayer.name}}
                            </h3>
                            <p>
                                {{singlePrayer.prayer}}
                            </p>
                        </div>
                        <div class="p1-meta">
                            <template v-if="prayer.allow_acknowledgements">
                                <div class="p1-meta-col">
                                    <h6>
                                        <i class="icon icon-book"></i>
                                        Prayed for
                                        <span
                                            class="p1-meta-count"
                                            :id="'acknowledgment_count_' + singlePrayer.id"
                                        >
                                            {{singlePrayer.acknowledgmentcount}}
                                        </span>

                                        <span :id="'acknowledgment_text_' + singlePrayer.id">
                                            <template v-if="singlePrayer.acknowledgmentcount == '1'">
                                                time
                                            </template>
                                            <template v-else>
                                                times
                                            </template>
                                        </span>
                                    </h6>
                                </div>
                            </template>
                            <div class="p1-meta-col"></div>
                            <template v-if="prayer.allow_acknowledgements">
                                <div class="p1-meta-col p1-button">
                                    <a
                                        @click.prevent="prayForThis(singlePrayer)"
                                        class="p1-acknowledgment-link"
                                        :class="{'disabled': singlePrayer.prayedFor}"
                                        :data-prayer="singlePrayer.id"
                                        :href="singlePrayer.acknowledgmenturl"
                                    >
                                    <span class="text">
                                        <template v-if="singlePrayer.prayedFor">
                                            Thanks for praying
                                        </template>
                                        <template v-else>
                                            I prayed for this
                                        </template>
                                    </span>
                                    </a>
                                </div>
                            </template>
                        </div>
                    </article>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import JQuery from 'jquery';
import Vue from 'vue';
import axios from 'axios';
let $ = JQuery;
window.jQuery = JQuery;
require('jquery-colorbox');

export default {
    props: {
        createPrayerUrl: String,
        prayer: Object
    },
    data() {
        return {
            prayers: undefined
        };
    },
    mounted() {
        this.getPrayers();
    },
    methods: {
        /**
         * Retrieve all the prayer requests from the server
         */
        getPrayers() {
            axios({
                url: `//api.${window.base_domain}/${window.Church.slug}/prayerCloud/prayers`,
                method: 'get'
            })
            // Ajax success
            .then(response => {
                this.prayers = response.data.data.show || [];

                // Update the "I prayed for this" buttons for requests the user already prayed for
                this.prayers.forEach(singlePrayer => {
                    var cookie = Cookies.get('acknowlegement_' + singlePrayer.id);
                    if (cookie) {
                        this.setPrayedFor(singlePrayer);
                    }
                });
            })
            // Ajax error
            .catch((error) => {
               console.log(error);
            });
        },

        /**
         * Open the prayer creation form in a modal
         *
         * @param {String} url - href for the colorbox iframe
         */
        createNewPrayer() {
            $.colorbox({
                height: 650,
                href: this.createPrayerUrl,
                iframe: true,
                maxHeight: "75%",
                maxWidth: "75%",
                opacity: 0.85,
                width: 860,
            });
        },

        /**
         * Make an ajax request to tell the server that you have prayed for an individual prayer request
         *
         * @param {Object} singlePrayer - all the information about an individual prayer request
         */
        prayForThis(singlePrayer) {
            // Prevent the user from submitting multiple ajax requests
            var cookie = Cookies.get('acknowlegement_' + singlePrayer.id);
            if (cookie || singlePrayer.prayedFor) {
                alert('You have already prayed for this request.');
                return;
            }

            // Update the "I prayed for this" button
            this.setPrayedFor(singlePrayer);

            // Tell the server you have prayed
            axios({
                url: singlePrayer.acknowledgmenturl,
                method: 'get',
                transformRequest: function (data, headers) {
                    // Prevent OPTIONS request, which will allow cross origin resource sharing (CORS) from mssl
                    delete headers.common['X-Requested-With'];
                    delete headers.common['X-CSRF-TOKEN'];
                    return data;
                }
            })
            // Ajax success
            .then(function (response) {
                singlePrayer.acknowledgmentcount = response.data.acknowledgement_count;

                Cookies.set('acknowlegement_' +  singlePrayer.id, true, { expires: 9999, path: '/' });
            })
            // Ajax error
            .catch(function (error) {
               console.log(error);
            });
        },

        /**
         * Add a new key to the prayer request that the UI will use to show you prayed
         *
         * @param {Object} singlePrayer - all the information about an individual prayer request
         */
        setPrayedFor(singlePrayer) {
            Vue.set(singlePrayer, 'prayedFor', true);
        }
    }
}
</script>

<style lang="scss" scoped>
    #prayers h2 {
        font-size: 20px;
    }

    #prayers h3 {
        font-size: 18px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 141px);
    }

    #prayers h6 {
        font-size: 14px;
    }

    // Share your prayer button
    #prayers .btn {
        border: 2px solid var(--user-alternate-color);
        color: var(--user-alternate-color);
    }

    // Individual prayer request
    #prayers article {
        background: #fff;
        margin-top: 16px;
        padding: 12px;
        position: relative;
    }

    #prayers .btn:hover {
        background: #fff;
    }

    // Pray for this button
    #prayers .p1-button a {
        background: var(--user-alternate-color);
        color: #fff;
        display: inline-block;
        padding: 6px 12px;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    #prayers .p1-button a:hover {
        text-decoration: none;
    }

    #prayers .p1-button a.disabled {
        background: #fff;
        color: var(--user-alternate-color);
        cursor: none;
    }

    #prayers .p1-meta-count {
        color: var(--user-alternate-color);
        font-size: 16px;
    }

    #prayers .icon {
        display: inline-block;
        height: 12px;
        margin-right: 4px;
        width: 12px;
    }

    #prayers .icon-book {
        background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"/></svg>');
    }
</style>
