import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state() {
        return {
            bibles: window.bibles,
            currentBibleAbbreviation: '',
            books: [],
            currentBookName: '',
            defaultBookName: 'genesis',
            currentChapterNumber: '',
            defaultChapterNumber: '1'
        };
    },
    getters: {
        /**
         * Determine the bible selected by the cms
         *
         * @param {Object} state - the current state of the store
         * @return {String}
         */
        defaultBibleAbbreviation(state) {
            return state.bibles.filter((bible) => {
                return bible.id === window.site.default_bible_api;
            })[0].abbreviation;
        },

        /**
         * Build a chapter id for making ajax requests to the api
         *
         * @param {Object} state - the current state of the store
         * @param {Object} getters - all getter functions in the store
         * @return {string}
         */
        currentChapterId(state, getters) {
            return getters.currentBook.id + '.' + state.currentChapterNumber;
        },

        /**
         * Get the full object for the current book
         *
         * @param {Object} state - the current state of the store
         * @return {Object} https://api.scripture.api.bible/v1/swagger.json#/definitions/Book
         */
        currentBook(state) {
            return state.books.filter(book => {
                // Add as special case when the currentBookName is "psalm" or "psalms"
                if (state.currentBookName.toLowerCase().indexOf('psalm') > -1) {
                    // CMS website users will always come to the reader with a currentBook of "psalms"
                    // ESV only supports "psalm" and all other translations only support "psalms"
                    // So we need to select a book that matches "psalm" or "psalms" to ensure we're showing the right
                    // book and handling switching between any translation of "psalms"
                    return book.name.toLowerCase() === 'psalms' || book.name.toLowerCase() === 'psalm';
                }
                return book.name.toLowerCase() === state.currentBookName;
            })[0];
        },

        /**
         * Get a url pointing to a specific chapter
         *
         * @param {Object} state - the current state of the store
         * @param {Object} getters - all getter functions in the store
         * @param {String} bibleAbbreviation - 3 letter abbreviation for the a specific bible translation
         * @param {String} id - String containing the book, chapter and verse (ex: ROM.1.3, ROM.1.3.2, ROM.1.3.2-2.3.1)
         * @return {String}
         */
        getChapterUrl: (state, getters) => (bibleAbbreviation, id) => {
            // id can contain multiple chapter ids separated by a dash but we only want to link to the first chapter
            id = id.split('-')[0];

            const bookName = getters.getBookNameById(id).toLowerCase();
            const chapterNumber = getters.getChapterNumberFromId(id);
            const verseNumber = getters.getVerseNumberFromId(id);
            // Make the book name url friendly by replacing spaces with dashes
            const bookNameForUrl = bookName.replace(/\s/g, '-');
            const versePath = verseNumber ? '/' + verseNumber : '';

            return '/' + bibleAbbreviation + '/' + bookNameForUrl + '/' + chapterNumber + versePath;
        },

        /**
         * Get the name of the book from the api.bible id
         *
         * @param {Object} state - the current state of the store
         * @param {String} id - String containing the book, chapter and verse (ex: ROM.1.3 or ROM.1.3.2)
         * @return {String}
         */
        getBookNameById: (state) => (id) => {
            const bookId = id.split('.')[0].toLowerCase();

            return state.books.filter(book => {
                return book.id.toLowerCase() === bookId;
            })[0].name;
        },

        /**
         * Get the chapter number from the api.bible id
         *
         * @param {Object} state - the current state of the store
         * @param {String} id - String containing the book id, chapter and verse (ex: ROM.1.3 or ROM.1.3.2)
         * @return {String}
         */
        getChapterNumberFromId: (state) => (id) => {
            return id.split('.')[1];
        },

        /**
         * Get the verse number from the api.bible id
         *
         * @param {Object} state - the current state of the store
         * @param {String} id - String containing the book id, chapter and verse (ex: ROM.1.3 or ROM.1.3.2)
         * @return {String}
         */
        getVerseNumberFromId: (state) => (id) => {
            return id.split('.')[2];
        }
    },
    mutations: {
        setCurrentBibleAbbreviation(state, bibleAbbreviation) {
            state.currentBibleAbbreviation = bibleAbbreviation;
        },
        setBooks(state, books) {
            state.books = books;
        },
        setCurrentBookName(state, bookName) {
            state.currentBookName = bookName;
        },
        setCurrentChapterNumber(state, chapterNumber) {
            state.currentChapterNumber = chapterNumber;
        }
    },
    actions: {
        /**
         * Update the current bible abbreviation to match the page we're on
         *
         * @param {Object} context - functions to modify the store
         * @param {String} bibleAbbreviation - 3 letters representing a bible translation
         */
        updateCurrentBibleAbbreviation(context, bibleAbbreviation) {
            // Don't bother processing the abbreviation if it hasn't changed
            if (context.state.currentBibleAbbreviation === bibleAbbreviation) {
                return;
            }

            // If there is an abbreviation passed in use it,
            // otherwise try to use a previously saved one
            bibleAbbreviation = bibleAbbreviation ||
                                localStorage.getItem('bibleAbbreviation');

            // If the bible abbreviation isn't one we support, fall back to to the default for the site
            if (!bibleAbbreviation ||!context.state.bibles.filter(bible => bible.abbreviation.toLowerCase() === bibleAbbreviation.toLowerCase()).length) {
                bibleAbbreviation = context.getters.defaultBibleAbbreviation;
            }

            bibleAbbreviation = bibleAbbreviation.toLowerCase();

            // Cache the abbreviation
            // So the next time the user visits the homepage they get their own preferred translation
            localStorage.setItem('bibleAbbreviation', bibleAbbreviation);

            // Now update the state
            context.commit('setCurrentBibleAbbreviation', bibleAbbreviation);
        },

        /**
         * Update the list of books whenever they are downloaded
         *
         * @param {Object} context - functions to modify the store
         * @param {Array} books - https://api.scripture.api.bible/v1/swagger.json#/definitions/Book
         */
        updateBooks(context, books) {
            context.commit('setBooks', books);
        },

        /**
         * Update the name of the current book based on the current url
         *
         * @param {Object} context - functions to modify the store
         * @param {String} bookName - name of the current book of the bible
         */
        updateCurrentBookName(context, bookName) {
            bookName = bookName || context.state.defaultBookName;
            // The books in the url replace all spaces with dashes, so undo that change to get the full book name
            bookName = bookName.replace(/-/g, ' ');

            context.commit('setCurrentBookName', bookName);
        },

        /**
         * Update the current chapter number based on the current url
         *
         * @param {Object} context - functions to modify the store
         * @param {String} chapterNumber - number of the current chapter
         */
        updateCurrentChapterNumber(context, chapterNumber) {
            chapterNumber = chapterNumber || context.state.defaultChapterNumber;
            // Urls can come from the tooltip like "/kjv/genesis/-2/1, so remove any dashes in the chapter number
            chapterNumber = chapterNumber.replace(/-/g, '');

            context.commit('setCurrentChapterNumber', chapterNumber);
        }
    }
});
