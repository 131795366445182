<template>
    <div class="bibleCloudSearch">
        <!-- Don't show any title when the searchResults is undefined (while loading results) -->
        <div
            v-if="searchResults"
            class="bibleCloudSearch-title"
        >
            <template v-if="searchResults.length">
                Search Results:
            </template>
            <template v-else>
                No Results for:
            </template>

            <span class="bibleCloudSearch-title-query">
                {{$route.query.q}}
            </span>
        </div>

        <div
            v-for="result in searchResults"
             class="bibleCloudSearch-result"
        >
            <div class="bibleCloudSearch-result-title">
                <router-link :to="getLink(result)">
                    {{result.reference}}
                </router-link>

                <span class="bibleCloudSearch-result-title-abbreviation">
                    ({{currentTranslation.abbreviation}})
                </span>
            </div>

            <div>
                {{result.text}}
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        props: {
            base_domain: String,
            currentTranslation: Object
        },
        data() {
            return {
                searchResults: undefined
            };
        },
        created() {
            // Make an ajax request for the search results as soon as the page loads
            this.getSearchResults();

            // Ensure the search input is shown when users navigate directly to the search page
            this.$emit('showSearch');
        },
        destroyed() {
            // Clear the search field whenever the user navigates away from the search page
            this.$emit('clearSearch');
        },
        watch: {
            /**
             * When the route changes load the appropriate chapter
             */
            $route() {
                this.getSearchResults();
            }
        },
        methods: {
            /**
             * Make an ajax call to get search results for this query
             */
            getSearchResults() {
                // Clear the search results page
                this.searchResults = undefined;

                // Show the loading overlay
                this.$emit('loading', true);

                // Handle searches for empty strings that would otherwise 400 when calling ajax
                if (this.$route.query.q === '') {
                    this.searchResults = {};
                    this.$emit('loading', false);
                } else {
                    // Make an ajax request to load search result data
                    axios({
                        url: `//api.${this.base_domain}/${this.currentTranslation.id}/search`,
                        method: 'get',
                        params: {
                            q: this.$route.query.q,
                        }
                    })
                    // Ajax success
                    .then(response => {
                        // Redirect users if they were searching for a chapter or verse
                        if (response.data.data.passages) {
                            const firstPassage = response.data.data.passages[0];
                            const url = this.$store.getters.getChapterUrl(
                                this.$store.state.currentBibleAbbreviation,
                                firstPassage.id
                            );

                            this.$router.replace(url);
                        } else {
                            // Display the verses their query appears in
                            this.searchResults = response.data.data.verses;
                        }
                    })
                    // Ajax error
                    .catch(error => {
                        this.searchResults = undefined;
                    })
                    // Ajax complete
                    .finally(() => {
                        this.$emit('loading', false);
                    });
                }
            },

            /**
             * Build the link url for each search result
             *
             * @param {Object} result - Information about a single search result
             * @return {string}
             */
            getLink(result) {
                return this.$store.getters.getChapterUrl(
                    this.$store.state.currentBibleAbbreviation,
                    result.id
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // Text that tells the user what they searched for
    .bibleCloudSearch-title {
        font-family: $font-family-baskervville;
        font-size: 34px;
        padding: 20px 0;
        text-align: center;
    }

    // String that was search for
    .bibleCloudSearch-title-query {
        font-family: $font-family-openSans;
    }

    // Grouping of book, chapter, verse, translation, and search result text
    .bibleCloudSearch-result {
        border-bottom: 1px solid $mediumGray;
        padding-bottom: 10px;
        margin-bottom: 28px;
    }

    // The book, chapter, verse, and translation
    .bibleCloudSearch-result-title {
        font-size: 20px;
        font-weight: 600;
    }

    // The book translation
    .bibleCloudSearch-result-title-abbreviation {
        color: $darkGray;
    }
</style>
