<template>
    <div class="bibleCloudNotFound">
        {{status}} | {{statusText}}
    </div>
</template>

<script>
    export default {
        props: {
            status: String,
            statusText: String
        },
        created() {
            // The 404 page isn't loading anything, so hide the loading spinner immediately
            this.$emit('loading', false);
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // Style the error text
    .bibleCloudNotFound {
        align-items: center;
        color: $darkGray;
        display: flex;
        font-size: 40px;
        height: 60vh;
        justify-content: center;
        text-transform: uppercase;
    }
</style>
