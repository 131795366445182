<template>
    <div
        class="liveStreamAndBibleCloudLayout"
        :class="{'liveStreamAndBibleCloudLayout-no-sidebar': !hasSidebar}"
        :style="colorStyles"
    >
        <div
            v-show="isNavExpanded || showOverlay"
            @click="$emit('overlayClicked')"
            class="navbar-expanded-overlay"
            :class="!isNavExpanded ? overlayClass : ''"
        ></div>

        <!-- Top navbar -->
        <nav
            class="navbar navbar-dark navbar-expand-lg"
            :class="{
                'navbar-expanded': isNavExpanded

            }"
        >
            <div class="navbar-logo-wrapper">
                <div>
                    <a
                        v-if="settings.logo_url"
                        href="/"
                    >
                        <img
                            alt="logo"
                             class="navbar-logo-wrapper-img"
                            height="60"
                            :src="settings.logo_url"
                        />
                    </a>
                </div>
            </div>

            <div class="d-flex">
                <!-- Allow additional content to appear in the navbar, to the right of the hamburger menu -->
                <slot name="navbar-extras"></slot>

                <!-- Only displays on smaller browsers / mobile devices that have navigation items -->
                <button
                    v-if="hasNavigation"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    class="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    ref="navbarToggler"
                    type="button"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>

            <!-- Actual navigation items, displayed on larger browsers and collapsable on smaller ones -->
            <div
                class="collapse navbar-collapse"
                id="navbarNavAltMarkup"
            >
                <div class="navbar-nav">
                    <!-- Links from e360's link list -->
                    <a
                        v-for="nav in settings.navigation"
                        class="nav-link"
                        :href="nav.url"
                        :target="nav.ifnewwindow ? '_blank' : ''"
                        :title="nav.name"
                    >
                        {{nav.name}}
                    </a>

                    <!-- Hardcoded social media icon links -->
                    <div class="navbar-social">
                        <a
                            v-if="church.social.facebook.link"
                            class="nav-link nav-link-social"
                            :href="church.social.facebook.link"
                            target="_blank"
                            title="Visit us on Facebook"
                        >
                            <simple-svg :filepath="getSvgFilePath('facebook-circle')"></simple-svg>
                        </a>

                        <a
                            v-if="church.social.twitter.link"
                            class="nav-link nav-link-social"
                            :href="church.social.twitter.link"
                            target="_blank"
                            title="Visit us on Twitter"

                        >
                            <simple-svg :filepath="getSvgFilePath('twitter')"></simple-svg>
                        </a>

                        <a
                            v-if="church.social.instagram.link"
                            class="nav-link nav-link-social"
                            :href="church.social.instagram.link"
                            target="_blank"
                            title="Visit us on Instagram"
                        >
                            <simple-svg :filepath="getSvgFilePath('instagram')"></simple-svg>
                        </a>

                        <a
                            v-if="church.social.youtube.link"
                            class="nav-link nav-link-social"
                            :href="church.social.youtube.link"
                            target="_blank"
                            title="Visit us on YouTube"
                        >
                            <simple-svg :filepath="getSvgFilePath('youtube')"></simple-svg>
                        </a>

                        <a
                            v-if="church.social.vimeo.link"
                            class="nav-link nav-link-social"
                            :href="church.social.vimeo.link"
                            target="_blank"
                            title="Visit us on Vimeo"
                        >
                            <simple-svg :filepath="getSvgFilePath('vimeo')"></simple-svg>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <div class="row no-gutters position-relative">
            <div
                class="liveStreamAndBibleCloudLayout-container col-12"
                :class="[containerClass, {
                    'col-lg-9': hasSidebar
                }]"
            >
                <!-- Main content for the page -->
                <slot name="content"></slot>
            </div>

            <!-- Content displayed on the side for larger browsers and under the stream for smaller browsers -->
            <div
                v-if="hasSidebar"
                class="liveStreamAndBibleCloudLayout-sidebar col-12 col-lg-3"
            >

                <!-- Allow some content (like a countdown) to display above the sidebar -->
                <slot name="sidebar-header"></slot>

                <div
                    class="liveStreamAndBibleCloudLayout-sidebar-toggles"
                    :class="sidebarClass"
                >
                    <template v-if="settings.chatembed">
                        <div
                            @click="setOpenTab('chat')"
                            @keyup.enter="setOpenTab('chat')"
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-header"
                            :class="{'collapsed': !showChat}"
                            tabindex="0"
                        >
                            <div class="liveStreamAndBibleCloudLayout-sidebar-toggle-header-text">
                                <simple-svg :filepath="getSvgFilePath('chat')"></simple-svg>
                                <span>
                                    CHAT
                                </span>
                            </div>

                            <simple-svg
                                class="chevron-arrow chevron-arrow-down"
                                :filepath="getSvgFilePath('chevron-down')"
                            ></simple-svg>
                            <simple-svg
                                class="chevron-arrow chevron-arrow-up"
                                :filepath="getSvgFilePath('chevron-up')"
                            ></simple-svg>
                        </div>

                        <div
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-body"
                            :class="{'collapse': !showChat}"
                        >
                            <span v-html="settings.chatembed"></span>
                        </div>
                    </template>

                    <template v-if="settings.prayer.enabled && createPrayerUrl">
                        <div
                            @click="setOpenTab('prayer')"
                            @keyup.enter="setOpenTab('prayer')"
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-header"
                            :class="{'collapsed': !showPrayer}"
                            tabindex="0"
                        >
                            <div class="liveStreamAndBibleCloudLayout-sidebar-toggle-header-text">
                                <simple-svg :filepath="getSvgFilePath('prayer')"></simple-svg>
                                <span>
                                    PRAYER
                                </span>
                            </div>

                            <simple-svg
                                class="chevron-arrow chevron-arrow-down"
                                :filepath="getSvgFilePath('chevron-down')"
                            ></simple-svg>
                            <simple-svg
                                class="chevron-arrow chevron-arrow-up"
                                :filepath="getSvgFilePath('chevron-up')"
                            ></simple-svg>
                        </div>

                        <div
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-body"
                            :class="{'collapse': !showPrayer}"
                        >
                            <sc-prayers
                                :createPrayerUrl="createPrayerUrl"
                                :prayer="settings.prayer"
                            ></sc-prayers>
                        </div>
                    </template>

                    <template v-if="$slots.details">
                        <div
                            @click="setOpenTab('details')"
                            @keyup.enter="setOpenTab('details')"
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-header"
                            :class="{'collapsed': !showDetails}"
                            tabindex="0"
                        >
                            <div class="liveStreamAndBibleCloudLayout-sidebar-toggle-header-text">
                                <simple-svg :filepath="getSvgFilePath('file-alt')"></simple-svg>
                                <span>
                                    DETAILS
                                </span>
                            </div>

                            <simple-svg
                                class="chevron-arrow chevron-arrow-down"
                                :filepath="getSvgFilePath('chevron-down')"
                            ></simple-svg>
                            <simple-svg
                                class="chevron-arrow chevron-arrow-up"
                                :filepath="getSvgFilePath('chevron-up')"
                            ></simple-svg>
                        </div>

                        <div
                            class="liveStreamAndBibleCloudLayout-sidebar-toggle-body"
                            :class="{'collapse': !showDetails}"
                        >
                            <!-- Let the parent component determine the custom content, elements, and styling for the details tab -->
                            <slot name="details"></slot>
                        </div>
                    </template>
                </div>
            </div>

            <!-- Display a reseller specific tagline, copyright, and logo -->
            <div
                v-if="site.tagline"
                class="liveStreamAndBibleCloudLayout-vendor-tagline"
            >
                <div>&copy;{{new Date().getFullYear()}} {{site.title}}</div>
                <simple-svg
                    class="liveStreamAndBibleCloudLayout-vendor-tagline-icon d-none d-sm-block"
                    :filepath="getSvgFilePath(site.tagline.logo_svg, '/')"
                    height="32px"
                ></simple-svg>
                <simple-svg
                    class="liveStreamAndBibleCloudLayout-vendor-tagline-icon d-sm-none"
                    :filepath="getSvgFilePath(site.tagline.logo_icon_svg)"
                    height="24px"
                ></simple-svg>
                <div class="d-none d-sm-block">Powered by: {{site.title}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {getSvgFilePathMixin} from '../../mixins/getSvgFilePathMixin';
import scPrayers from '../../components/streaming/scPrayers';
import {SimpleSVG} from 'vue-simple-svg';

export default {
    components: {
        scPrayers,
        'simple-svg': SimpleSVG
    },
    mixins: [getSvgFilePathMixin],
    props: {
        church: Object,
        containerClass: String,
        createPrayerUrl: String,
        settings: Object,
        showOverlay: Boolean,
        sidebarClass: String,
        site: Object,
        overlayClass: String
    },
    data() {
        return {
            isNavExpanded: false,
            lgBreakpoint: 992,
            showChat: false,
            showDetails: false,
            showPrayer: false
        }
    },
    mounted() {
        // Only open a default tab when not in the "mobile" view
        if (window.innerWidth >= this.lgBreakpoint) {
            // Ensure the correct tab is open when the page 1st loads
            if (this.settings.chatembed && this.settings.defaulttab === 'chat') {
                this.setOpenTab('chat');
            } else if (this.settings.prayer.enabled && this.createPrayerUrl && this.settings.defaulttab === 'prayer') {
                this.setOpenTab('prayer');
            } else if (this.$slots.details) {
                this.setOpenTab('details');
            }
        }

        const $navbarCollapse = $('.navbar-collapse');

        // Listen for bootstrap's navigation events to determine if the nav is expanded or collapsed
        $navbarCollapse.on('show.bs.collapse', () => {
           this.isNavExpanded = true;
           this.$emit('navToggled', true);
        });

        $navbarCollapse.on('hidden.bs.collapse', () => {
            this.isNavExpanded = false
            this.$emit('navToggled', false);
        });
    },
    computed: {
        /**
         * Expose user set css variables for use throughout the embed
         */
        colorStyles() {
            return {
                '--user-alternate-color': this.settings.alternate_color,
                '--user-header-color': this.settings.header_color,
                '--user-link-color': this.settings.link_color
            };
        },

        /**
         * Are there any items going to be displayed in the navigation
         */
        hasNavigation() {
            return !!this.settings.navigation.length ||
                !!this.church.social.facebook.link ||
                !!this.church.social.twitter.link ||
                !!this.church.social.instagram.link ||
                !!this.church.social.youtube.link ||
                !!this.church.social.vimeo.link;

        },

        /**
         * Determine if the sidebar has content to display
         */
        hasSidebar() {
            return !!this.$slots['sidebar-header'] ||
                !!this.settings.chatembed ||
                !!(this.settings.prayer.enabled && this.createPrayerUrl) ||
                !!this.$slots.details;

        }
    },
    methods: {
        /**
         * Expand / collapse a specific tab
         * On smaller browsers only a single tab can be shown at once
         * On larger browsers multiple tabs can be shown at once
         *
         * @param {String} tab - name of the tab to be shown
         */
        setOpenTab(tab) {
            if (!this.hasSidebar) {
                return;
            }

            // If the browser is smaller then ensure only 1 tab is open at a time
            const isSmallBrowser = window.innerWidth < this.lgBreakpoint;

            if (tab === 'chat') {
                this.showChat = !this.showChat;
                if (isSmallBrowser) {
                    this.showPrayer = false;
                    this.showDetails = false;
                }
            } else if (tab === 'prayer') {
                this.showPrayer = !this.showPrayer;
                if (isSmallBrowser) {
                    this.showChat = false;
                    this.showDetails = false;
                }
            } else if (tab === 'details') {
                this.showDetails = !this.showDetails;
                if (isSmallBrowser) {
                    this.showChat = false;
                    this.showPrayer = false;
                }
            }

            this.$emit('sidebarToggled', this.showChat || this.showPrayer || this.showDetails);
        }
    }
}
</script>

<style lang="scss">
    // Override default sermon cloud styles
    body {
        font-size: 16px;
    }

    html,
    body,
    #app {
        height: auto;
    }
    // End sermon cloud overrides
</style>

<style lang="scss" scoped>
    @import './resources/sass/biblecloud/variables';

    $vendor-tagline-height: 40px;

    * {
        font-family: $font-family-openSans;
    }

    // Customizations to bootstrap
    .navbar {
        background: var(--user-header-color);
        min-height: $navbar-height;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: $zindex-dropdown + 1;
    }

    .navbar-logo-wrapper-img {
        max-height: 60px;
        height: auto;
        padding-right: 20px;
    }

    .navbar-social {
        display: flex;
        justify-content: center;
    }

    .navbar-dark .navbar-nav .nav-link {
        color: var(--user-link-color);
        padding-left: 0.625rem;
        padding-right: 0.625rem;

        // Social media icons
        ::v-deep svg {
            fill: var(--user-link-color);
            height: 26px;
        }

        &:hover,
        &:hover ::v-deep svg {
            color: var(--user-alternate-color);
            fill: var(--user-alternate-color);
        }
    }
    // End bootstrap customizations

    // "Sidebar" content is displayed on the right side for larger browsers and under the stream for smaller browsers
    .liveStreamAndBibleCloudLayout-sidebar {
        background: $offWhite;
        z-index: $zindex-dropdown - 2;
    }

    // Toggles should take up the full height on larger browsers and the full width on smaller browsers
    .liveStreamAndBibleCloudLayout-sidebar-toggles {
        display: flex;
    }

    // Toggle headers are the clickable areas that cause expand / collapse
    .liveStreamAndBibleCloudLayout-sidebar-toggle-header {
        background: $white;
        border-bottom: 1px solid $lightGray;
        color: $darkGray;
        cursor: pointer;
        font-weight: 500;
        padding: 8px 12px;
    }

    // Toggle header text contains an icon and text (no chevrons)
    .liveStreamAndBibleCloudLayout-sidebar-toggle-header-text {
        align-items: center;
        display: flex;

        ::v-deep svg {
            display: flex;
            fill: $darkGray;
            height: 16px;
            margin-right: 20px;
        }
    }

    // Toggle body is where the content is displayed
    .liveStreamAndBibleCloudLayout-sidebar-toggle-body {
        background: $offWhite;
        padding: 12px;
    }

    // Vendor tagline displays at the bottom of the page with copyright, icon, and a link to the reseller
    .liveStreamAndBibleCloudLayout-vendor-tagline {
        align-items: center;
        background: var(--user-header-color);
        bottom: 0;
        color: var(--user-link-color);
        display: flex;
        height: $vendor-tagline-height;
        justify-content: space-between;
        padding: 10px 12px;
        position: fixed;
        right: 0;
        width: 100%;
        z-index: $zindex-dropdown - 1;
    }

    // The reseller icon in the tagline
    .liveStreamAndBibleCloudLayout-vendor-tagline-icon ::v-deep svg {
        height: 24px;
        fill: var(--user-link-color);
    }

    // For screens sized "lg" and wider
    @include media-breakpoint-up(lg) {
        // Ensure the nav links display on the right side
        .navbar-collapse {
            justify-content: flex-end;
        }

        // Make space at the top and bottom of the page for position: fixed navbar and tagline
        .liveStreamAndBibleCloudLayout-container,
        .liveStreamAndBibleCloudLayout-sidebar {
            padding-bottom: $vendor-tagline-height;
            padding-top: $navbar-height;
        }

        // Ensure the sidebar takes up the full height of the browser window and does not scroll with content
        .liveStreamAndBibleCloudLayout-sidebar {
            border-left: 1px solid $lightGray;
            display: flex;
            height: 100%;
            position: fixed;
            right: 0;
        }

        // Force the sidebar expand / collapse sections to take up the full browser height
        .liveStreamAndBibleCloudLayout-sidebar-toggles {
            flex-direction: column;
            width: 100%;
        }

        // Add up / down arrows to the clickable expand / collapse buttons
        .liveStreamAndBibleCloudLayout-sidebar-toggle-header {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .chevron-arrow ::v-deep svg {
                height: 24px;
                fill: var(--user-alternate-color);
            }

            .chevron-arrow-down {
                display: none;
            }

            .chevron-arrow-up {
                display: inline-block;
            }

            &.collapsed {
                ::v-deep {
                    .chevron-arrow-down {
                        display: inline-block;
                    }

                     .chevron-arrow-up {
                        display: none;
                    }
                }
            }
        }

        // Ensure the sidebar sections are evenly sized when expanded
        .liveStreamAndBibleCloudLayout-sidebar-toggle-body {
            flex: 1 1 0;
            overflow: auto;
        }
    }

    // For screens sized "md" and narrower
    @include media-breakpoint-down(md) {
        // Bootstrap customizations
        .navbar-dark .navbar-nav {
            text-align: center;

            // Add lines between each nav link
            .nav-link:not(.nav-link-social) {
                border-bottom: 1px solid $darkGray;
            }
        }

        .navbar-expanded {
            box-shadow: 0 4px 4px $darkGray-80;
        }

        .navbar-expanded-overlay {
            background: $darkGray-80;
            position: fixed;
            top: 0;
            left: 0;
            z-index: $zindex-dropdown;
            height: 100%;
            width: 100%;
        }

        // Style the hamburger menu in the navigation
        .navbar-toggler {
            border-color: var(--user-link-color);
            color: var(--user-link-color);
        }
        // End bootstrap customizations

        // Add space above & below to make room for the navbar, tagline,
        // and sidebar toggles (which are now on the bottom of the page)
        // which are all position: fixed
        .liveStreamAndBibleCloudLayout-container {
            padding-bottom: $vendor-tagline-height + 45px;
            padding-top: $navbar-height;
        }

        // Adjust space below when there is no sidebar
        .liveStreamAndBibleCloudLayout-no-sidebar .liveStreamAndBibleCloudLayout-container {
            padding-bottom: $vendor-tagline-height;
        }

        // Move the sidebar to the bottom of the of the page
        .liveStreamAndBibleCloudLayout-sidebar {
            bottom: $vendor-tagline-height;
            position: static;
        }

        // Ensure the sidebar toggles do not scroll with the page
        .liveStreamAndBibleCloudLayout-sidebar-toggles {
            bottom: $vendor-tagline-height;
            box-shadow: 0px -8px 14px 0px $darkGray-20;
            position: fixed;
            width: 100%;
        }

        // Make the expand / collapse buttons span the entire width of the page and be a solid color when selected
        .liveStreamAndBibleCloudLayout-sidebar-toggle-header {
            background: $darkGray;
            border-top: 1px solid $lightGray;
            color: $white;
            flex: 1;
            font-size: 12px;
            padding-bottom: 0;
            text-align: center;

            &.collapsed {
                background: $white;
                color: $darkGray;

                // Update the sidebar toggle icon colors
                .liveStreamAndBibleCloudLayout-sidebar-toggle-header-text ::v-deep svg {
                    fill: $darkGray;
                }
            }

            .liveStreamAndBibleCloudLayout-sidebar-toggle-header-text {
                display: block;

                ::v-deep {
                    svg {
                        display: block;
                        fill: $white;
                        margin: auto;
                    }
                }
            }

            // Smaller browser will not show the sidebar toggle arrows
            ::v-deep .chevron-arrow {
                display: none;
            }

            .liveStreamAndBibleCloudLayout-sidebar-toggle-body + & {
                border-left: 1px solid $lightGray;
            }
        }

        // Make the sidebar sections full width and display above the expand / collapse buttons
        .liveStreamAndBibleCloudLayout-sidebar-toggle-body {
            border-top: 1px solid $lightGray;
            bottom: 44px;
            box-shadow: 0px -8px 24px 0px $mediumGray;
            height: 35vh;
            min-height: 180px;
            overflow: auto;
            position: absolute;
            width: 100%;
        }

        // Make the tagline smaller to fit smaller browsers better
        .liveStreamAndBibleCloudLayout-vendor-tagline {
            font-size: 12px;
        }
    }
</style>
