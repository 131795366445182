<template>
    <bible-cloud-dropdown
        :buttonText="currentChapterNumber"
        class="bibleCloudChapterSelector"
        :disabled="disabled"
        placeholderText="CH"
    >
        <div class="bibleCloudChapterSelector-menu">
            <div
                v-for="chapterSummary in chapterSummaries"
                class="bibleCloudChapterSelector-cell"
            >
                <router-link
                    class="bibleCloudChapterSelector-chapter"
                    :to="getChapterUrl(chapterSummary)"
                >
                    {{getChapterNumber(chapterSummary)}}
                </router-link>
            </div>
        </div>
    </bible-cloud-dropdown>
</template>

<script>
    import BibleCloudDropdown from '@app/BibleCloudDropdown';

    export default {
        components: {
            BibleCloudDropdown,
        },
        props: {
            disabled: Boolean
        },
        data() {
            return {
            };
        },
        computed: {
            /**
             * Get a list of all chapter summaries for this book
             *
             * @return {Array}
             */
            chapterSummaries() {
                return this.$store.getters.currentBook.chapters;
            },

            /**
             * Get the number for the current chapter
             *
             * @return {String}
             */
            currentChapterNumber() {
                return this.$store.state.currentChapterNumber;
            },
        },
        methods: {
            /**
             * Get the chapter "number" to display in the dropdown toggle and menu
             *
             * @param {Object} chapterSummary - a small chunk of information about a chapter
             * @return {String|null}
             */
            getChapterNumber(chapterSummary) {
                if (chapterSummary) {
                    // Ensure the 1st letter is capitalized so chapter "intro" will print properly
                    return chapterSummary.number.charAt(0).toUpperCase() + chapterSummary.number.slice(1);
                }
            },

            /**
             * Navigate to a specific chapter
             *
             * @param {Object} chapterSummary - a small chunk of information about a chapter
             */
            getChapterUrl(chapterSummary) {
                return this.$store.getters.getChapterUrl(
                    this.$store.state.currentBibleAbbreviation,
                    chapterSummary.id
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    // Customize the width of the dropdown menu
    ::v-deep {
        .dropdown-menu {
            width: 268px;
        }
    }

    // Customize the width of the dropdown toggle button
    .bibleCloudChapterSelector {
        width: 76px
    }

    // Customize the spacing of the dropdown menu
    .bibleCloudChapterSelector-menu {
        display: flex;
        flex-wrap: wrap;
        margin: 3px 3px -13px 2px;
    }

    // Ensure the chapter numbers display in a 3 column row if possible
    // And make the numbers in the last row fill up all remaining space (when there is only 2 or 1 chapter in the row)
    .bibleCloudChapterSelector-cell {
        flex-grow: 1;
        width: 33%;
    }

    // Clickable chapter numbers
    .bibleCloudChapterSelector-chapter {
        border-radius: 4px;
        box-shadow: 0px 0px 3px $mediumGray;
        color: $textColor;
        display: block;
        line-height: 42px;
        margin: 7px;
        text-align: center;
        text-decoration: none;

        &:hover {
            box-shadow: 0px 0px 14px $mediumGray;
        }
    }
</style>
